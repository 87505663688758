import { APP_VERSION } from './version';

export const environment = {
  production: true,
  version: APP_VERSION,
  sentryUrl: 'https://31ab89cb0b1546249ec48d62e90aeda2@sentry.happycake.co/7',
  keycloakUrl: 'https://auth.happytech.app/auth',
  keycloakRealm: 'happy',
  keycloakClientId: 'kiosk-frontend'
};
